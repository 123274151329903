import { getUserId } from "./user"

export function convert24HourTo12Hour(time: string) {
  const hours = parseInt(time.split(":")[0])
  const minutes = time.split(":")[1]
  return `${hours > 12 ? hours - 12 : hours}:${minutes} ${hours >= 12 ? "PM" : "AM"}`
}

export function doAction(actionType: string, actionData: any | null = null) {
  fetch(`${process.env.REACT_APP_API_URL}/actions/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      actionType,
      actionData: actionData ?? {},
      userId: getUserId(),
    }),
  })
}
