import React from "react"
import "./App.css"
import "react-datepicker/dist/react-datepicker.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import RoutePicker from "./RoutePicker"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import LandingPage from "./LandingPage"

const theme = createTheme({
  palette: {
    primary: {
      light: "#115293",
      main: "#1976d2",
      dark: "#4791db",
      contrastText: "#fff",
    },
    secondary: {
      light: "#29a37f",
      main: "#008661",
      dark: "#006645",
      contrastText: "#000",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        disabled: {
          color: "#ffffff4d",
          backgroundColor: "#ffffff1f",
        },
      },
    },
  },
})

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div className="App">
          <LandingPage />
        </div>
      ),
    },
    {
      path: "/book",
      element: (
        <div className="App">
          <RoutePicker />
        </div>
      ),
    },
  ])

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
