let userId: string | null = null

export function getUserId() {
  if (userId) {
    return userId
  }

  const existingUserId = localStorage.getItem("userId")
  if (existingUserId) {
    userId = existingUserId
    return userId
  }

  userId = crypto.randomUUID()
  localStorage.setItem("userId", userId)
  return userId
}
