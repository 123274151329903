import { useState, useEffect, useCallback } from "react"

export interface Route {
  name: string
  code: string
  destinations: {
    code: string
    name: string
    schedules: {
      date: string
      dateTime: string
      times: string[]
    }[]
  }[]
}

export interface LiveRouteDetails {
  availableTimes: string[]
}

export function useRoutes() {
  const [allRoutes, setAllRoutes] = useState<Route[]>([])
  const [routeById, setRouteById] = useState<Record<string, Route>>({})

  const fetchRoutes = useCallback(async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/routes/`)
    const data = await response.json()

    // Remove any routes that have no schedules
    const routesWithSchedules = data.routes.filter((route: Route) =>
      route.destinations.some((destination) => destination.schedules.length > 0)
    )

    setAllRoutes(routesWithSchedules)
    setRouteById(Object.fromEntries(routesWithSchedules.map((route: Route) => [route.code, route])))
  }, [])

  const getRouteDetails = useCallback(
    async (departureCode: string, arrivalCode: string, date: Date): Promise<LiveRouteDetails> => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/routes/${departureCode}/${arrivalCode}/${date.toISOString().split("T")[0]}/`
      )
      return await response.json()
    },
    []
  )

  useEffect(() => {
    fetchRoutes()
  }, [fetchRoutes])

  return { allRoutes, routeById, getRouteDetails }
}
