import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material"
import { RequestedRoute } from "./types"
import { useMemo } from "react"
import { convert24HourTo12Hour } from "./utils"
import { Delete } from "@mui/icons-material"

interface Props {
  requestedRoutes: RequestedRoute[]
  setRequestedRoutes?: (requestedRoutes: RequestedRoute[]) => void
}

export function RequestedRoutes({ requestedRoutes, setRequestedRoutes }: Props) {
  const groupedByRoute = useMemo(() => {
    const groupedRoutes = requestedRoutes.reduce((acc, route) => {
      const key = `${route.departureTerminal}-${route.arrivalTerminal}`
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(route)
      return acc
    }, {} as Record<string, RequestedRoute[]>)

    for (const key in groupedRoutes) {
      const routes = groupedRoutes[key]
      const sortedRoutes = routes.sort((a, b) => a.date.getTime() - b.date.getTime())
      groupedRoutes[key] = sortedRoutes
    }
    return groupedRoutes
  }, [requestedRoutes])

  return (
    <div>
      {Object.entries(groupedByRoute).map(([key, routes]) => (
        <div key={key}>
          <TableContainer sx={{ maxWidth: 415, marginTop: 2 }} component={Paper} className="route-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} sx={{ fontSize: 16 }}>
                    {routes[0].departureTerminalName} -&gt; {routes[0].arrivalTerminalName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Times</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {routes.map((route) => (
                  <TableRow
                    key={`${route.date.toISOString()}-${route.times
                      .sort()
                      .map((t) => convert24HourTo12Hour(t))
                      .join(", ")}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ width: "80px" }}>
                      {route.date.toLocaleDateString()}
                    </TableCell>
                    <TableCell align="left">{route.times.map((t) => convert24HourTo12Hour(t)).join(", ")}</TableCell>
                    <TableCell align="right" width="20px">
                      {setRequestedRoutes && (
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => setRequestedRoutes?.(requestedRoutes.filter((r) => r !== route))}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <span>{route.date.toLocaleDateString()}</span>
            <span>
            </span>
            <span>{route.times.join(", ")}</span> */}
        </div>
      ))}
    </div>
  )
}
