import { useEffect, useState } from "react"
import { useRoutes } from "./useRoutes"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonProps,
  styled,
  CircularProgress,
} from "@mui/material"
import DatePicker from "react-datepicker"
import Checkout from "./Checkout"
import { RequestedRoute } from "./types"
import { RequestedRoutes } from "./RequestedRoutes"
import ActionsFooter from "./ActionsFooter"
import { convert24HourTo12Hour } from "./utils"
import { Add } from "@mui/icons-material"
import { doAction } from "./actions"
import { blue, grey } from "@mui/material/colors"

const NotifyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(blue[600]),
  backgroundColor: blue[600],
  "&:hover": {
    backgroundColor: blue[700],
  },
  // When activated, button should be grey.
  "&.selected": {
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
  },
}))

function RoutePicker() {
  const { allRoutes, routeById, getRouteDetails } = useRoutes()

  const [addRouteFlowVisible, setAddRouteFlowVisible] = useState<boolean>(true)
  const [departureTerminalCode, setDepartureCode] = useState<string>("")
  const [arrivalTerminalCode, setArrivalCode] = useState<string>("")
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedTimes, setSelectedTimes] = useState<string[]>([])
  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false)
  const [requestedRoutes, setRequestedRoutes] = useState<RequestedRoute[]>([])

  const departureRoute = departureTerminalCode ? routeById[departureTerminalCode] : null
  const arrivalRoute =
    departureTerminalCode && arrivalTerminalCode
      ? departureRoute?.destinations.find((d) => d.code === arrivalTerminalCode)
      : null

  const currentSchedule = selectedDate
    ? arrivalRoute?.schedules.find((s) => s.date === selectedDate.toISOString().split("T")[0])
    : null

  const [liveAvailableTimes, setAvailableTimes] = useState<Set<string> | null>(null)

  useEffect(() => {
    doAction("OnRoutePickerPage")
  }, [])

  useEffect(() => {
    async function fetchRouteDetails() {
      if (!departureTerminalCode || !arrivalTerminalCode || !selectedDate) return

      const newRouteDetails = await getRouteDetails(departureTerminalCode, arrivalTerminalCode, selectedDate)
      setAvailableTimes(new Set(newRouteDetails.availableTimes))
    }

    setAvailableTimes(null)
    fetchRouteDetails()
  }, [departureTerminalCode, arrivalTerminalCode, selectedDate, getRouteDetails])

  useEffect(() => {
    if (requestedRoutes.length === 0) {
      setIsCheckingOut(false)
      setAddRouteFlowVisible(true)
    }
  }, [requestedRoutes])

  return (
    <div className="route-picker-container">
      <div className="route-picker-scroll-container">
        <div className="route-picker">
          {isCheckingOut && (
            <Checkout
              requestedRoutes={requestedRoutes}
              setRequestedRoutes={setRequestedRoutes}
              setIsCheckingOut={setIsCheckingOut}
            />
          )}
          <div>
            {!isCheckingOut && (
              <>
                {requestedRoutes.length > 0 && (
                  <div className="route-picker-requested-routes">
                    <h3>Routes to be notified for:</h3>
                    <RequestedRoutes requestedRoutes={requestedRoutes} setRequestedRoutes={setRequestedRoutes} />
                  </div>
                )}

                <div className="route-picker-selections">
                  {!addRouteFlowVisible && (
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          doAction("ClickedAddMoreRoutes")
                          setAddRouteFlowVisible(true)
                        }}
                        startIcon={<Add />}
                      >
                        Add more routes
                      </Button>
                    </div>
                  )}
                  {addRouteFlowVisible && (
                    <>
                      {requestedRoutes.length === 0 && (
                        <div>
                          <h3>Select a route</h3>
                        </div>
                      )}
                      <div className="route-picker-row">
                        <FormControl fullWidth>
                          <InputLabel id="d-route-label">Leave from</InputLabel>
                          <Select
                            labelId="d-route-label"
                            id="d-route-select"
                            label="Leave from"
                            value={departureTerminalCode}
                            onChange={(e) => {
                              doAction("SelectedDepartureTerminal", { terminalCode: e.target.value })
                              setDepartureCode(e.target.value)
                              setSelectedDate(null)
                              setAvailableTimes(null)
                            }}
                          >
                            {allRoutes.map((route) => (
                              <MenuItem value={route.code}>{route.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="route-picker-row">
                        <FormControl fullWidth>
                          <InputLabel id="a-route-label">Going to</InputLabel>
                          <Select
                            labelId="a-route-label"
                            id="a-route-select"
                            label="Going to"
                            value={arrivalTerminalCode}
                            onChange={(e) => {
                              doAction("SelectedArrivalTerminal", { terminalCode: e.target.value })
                              setArrivalCode(e.target.value)
                              setSelectedDate(null)
                              setAvailableTimes(null)
                            }}
                            disabled={!departureTerminalCode}
                          >
                            {departureRoute &&
                              departureRoute.destinations
                                .filter((d) => d.schedules.length > 0)
                                .map((route) => <MenuItem value={route.code}>{route.name}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="route-picker-row">
                        <DatePicker
                          key={`${departureTerminalCode}-${arrivalTerminalCode}`}
                          selected={selectedDate}
                          onChange={(date) => {
                            doAction("SelectedDate", { date: date?.toISOString().split("T")[0] ?? "" })
                            setSelectedDate(date)
                          }}
                          includeDates={arrivalRoute?.schedules.map((s) => new Date(s.dateTime))}
                          disabled={!departureTerminalCode || !arrivalTerminalCode}
                          customInput={
                            <FormControl fullWidth>
                              <InputLabel id="date-select-label">Date</InputLabel>
                              <Select
                                labelId="date-select-label"
                                id="date-select"
                                value={selectedDate ? "selected-date" : ""}
                                label="Date"
                                open={false}
                                onChange={() => {}}
                              >
                                {Boolean(selectedDate) && (
                                  <MenuItem value="selected-date">
                                    {selectedDate?.toISOString().split("T")[0] ?? "Select date"}
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
                {addRouteFlowVisible && currentSchedule && (
                  <div className="route-picker-times-container">
                    <h3 className="route-picker-times-heading">Departure times</h3>
                    {liveAvailableTimes === null && (
                      <div className="route-picker-loading">
                        <CircularProgress size={36} />
                      </div>
                    )}
                    {liveAvailableTimes !== null && (
                      <TableContainer sx={{ maxWidth: 415, marginTop: 2 }} component={Paper} className="route-table">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Time</TableCell>
                              <TableCell align="center">Status</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentSchedule.times.map((time) => (
                              <TableRow key={time} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  {convert24HourTo12Hour(time)}
                                </TableCell>
                                <TableCell align="right" sx={{ fontWeight: 500 }}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      color: liveAvailableTimes?.has(time) ? "#066606" : "#d60707",
                                    }}
                                  >
                                    {liveAvailableTimes?.has(time) ? "Available" : "Sold out"}
                                  </span>
                                </TableCell>
                                <TableCell align="right">
                                  {liveAvailableTimes?.has(time) ? (
                                    <Button
                                      size="small"
                                      href="https://www.bcferries.com/RouteSelectionPage"
                                      rel="noreferrer"
                                      variant="outlined"
                                      color="info"
                                      target="_blank"
                                      onClick={() => {
                                        doAction("ClickedBookAtBCFerries", { time: time })
                                      }}
                                      style={{ width: 120 }}
                                    >
                                      Book now
                                    </Button>
                                  ) : (
                                    <NotifyButton
                                      size="small"
                                      variant={selectedTimes.includes(time) ? "contained" : "contained"}
                                      style={{ width: 120 }}
                                      className={selectedTimes.includes(time) ? "selected" : ""}
                                      onClick={() => {
                                        doAction("ClickedWaitlistTime", { time: time })
                                        if (selectedTimes.includes(time)) {
                                          setSelectedTimes(selectedTimes.filter((t) => t !== time))
                                        } else {
                                          setSelectedTimes([...selectedTimes, time])
                                        }
                                      }}
                                    >
                                      {selectedTimes.includes(time) ? "Remove" : "Notify me"}
                                    </NotifyButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                )}

                <ActionsFooter
                  showFooter={Boolean(addRouteFlowVisible && selectedTimes.length) || Boolean(requestedRoutes.length)}
                  canContinue={
                    (addRouteFlowVisible && Boolean(selectedTimes.length)) ||
                    (Boolean(requestedRoutes.length) && !addRouteFlowVisible)
                  }
                  canCancel={true}
                  cancelLabel={addRouteFlowVisible ? "Cancel" : "Back to start"}
                  onContinue={() => {
                    if (addRouteFlowVisible) {
                      // For typescript
                      if (!selectedDate) return

                      doAction("ClickedAddRoute", {
                        departureTerminal: departureRoute?.code ?? "",
                        arrivalTerminal: arrivalRoute?.code ?? "",
                        departureTerminalName: departureRoute?.name ?? "",
                        arrivalTerminalName: arrivalRoute?.name ?? "",
                        date: selectedDate,
                        times: selectedTimes,
                      })

                      setRequestedRoutes([
                        ...requestedRoutes,
                        {
                          departureTerminal: departureRoute?.code ?? "",
                          arrivalTerminal: arrivalRoute?.code ?? "",
                          departureTerminalName: departureRoute?.name ?? "",
                          arrivalTerminalName: arrivalRoute?.name ?? "",
                          date: selectedDate,
                          times: selectedTimes,
                        },
                      ])

                      setDepartureCode("")
                      setArrivalCode("")
                      setSelectedDate(null)
                      setSelectedTimes([])
                      setAddRouteFlowVisible(false)
                      setAvailableTimes(null)
                    } else {
                      setIsCheckingOut(true)
                    }
                  }}
                  onCancel={() => {
                    doAction("ClickedCancelRoute")

                    if (requestedRoutes.length) {
                      // We're adding an additional route. Cancel should hide the route picker too.
                      if (addRouteFlowVisible) {
                        setDepartureCode("")
                        setArrivalCode("")
                        setSelectedDate(null)
                        setSelectedTimes([])
                        setAvailableTimes(null)
                        setAddRouteFlowVisible(false)
                      } else {
                        setRequestedRoutes([])
                        setDepartureCode("")
                        setArrivalCode("")
                        setSelectedDate(null)
                        setSelectedTimes([])
                        setAvailableTimes(null)
                        setAddRouteFlowVisible(true)
                      }
                    } else {
                      // We're adding the first route. Just clear the selected times
                      setSelectedTimes([])
                    }
                  }}
                  continueLabel="Continue"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoutePicker
