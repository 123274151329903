import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"
import { useEffect } from "react"
import { doAction } from "./actions"

export default function LandingPage() {
  const navigate = useNavigate()

  useEffect(() => {
    doAction("OnLandingPage")
  }, [])

  return (
    <div className="landing-page">
      <div className="landing-page-content">
        <div className="landing-page-cta">
          <div className="landing-page-cta-text">
            BC Ferries sold out?
            <br />
            We'll text you when a spot opens up.
          </div>
          <img src="/phone.png" alt="phone" className="landing-page-cta-phone" />

          <Button
            className="landing-page-cta-button"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              doAction("ClickedLandingPageCTA")
              navigate("/book")
            }}
          >
            Get Started
          </Button>
          <div className="landing-page-footer">
            <div style={{ marginBottom: "10px", marginTop: "4px" }}>
              Was this helpful? Consider{" "}
              <a target="_blank" rel="noopener noreferrer" href="https://buymeacoffee.com/nextsailing">
                buying me a coffee ☕️
              </a>
              .
              <br />
            </div>
            <div>Built with ❤️ in Victoria.</div>
            <div>
              Next Sailing is not affiliated with BC Ferries. <br />
            </div>
            <div>
              Contact: <a href="mailto:nextsailing@hookshot.ca">nextsailing@hookshot.ca</a>.
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-footer">
        <a target="_blank" rel="noopener noreferrer" href="https://hookshot.ca/privacy">
          Privacy Policy
        </a>
      </div>
    </div>
  )
}
