import { RequestedRoute } from "./types"
import { useCallback, useEffect, useState } from "react"
import ActionsFooter from "./ActionsFooter"
import { RequestedRoutes } from "./RequestedRoutes"
import { MuiTelInput, matchIsValidTel } from "mui-tel-input"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { doAction } from "./actions"
import { getUserId } from "./user"

interface Props {
  requestedRoutes: RequestedRoute[]
  setRequestedRoutes: (requestedRoutes: RequestedRoute[]) => void
  setIsCheckingOut: (isCheckingOut: boolean) => void
}

function Checkout({ requestedRoutes, setRequestedRoutes, setIsCheckingOut }: Props) {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberValid, setPhoneNumberValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [submittingState, setSubmittingState] = useState<"idle" | "submitting" | "success" | "error">("idle")

  useEffect(() => {
    doAction("ViewedCheckout")
  }, [])

  const submitNotificationRequest = useCallback(async () => {
    const setupSubResponse = await fetch(`${process.env.REACT_APP_API_URL}/notification_requests/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: phoneNumber,
        requestedRoutes: requestedRoutes.map((r) => ({
          ...r,
          date: r.date.toISOString().split("T")[0],
        })),
        user_uuid: getUserId(),
      }),
    })

    if (!setupSubResponse.ok) {
      setSubmittingState("error")

      let errorMessage = "Unexpected error, please try again later."

      if (setupSubResponse.status === 400 && setupSubResponse.body) {
        try {
          const errorBody = await setupSubResponse.json()

          if (errorBody.detail) {
            errorMessage = errorBody.detail
          }
        } catch (e) {
          // Fall through to default error message
        }
      }

      setErrorMessage(errorMessage)
      return
    }
  }, [phoneNumber, requestedRoutes])

  return (
    <div>
      <div className="checkout-section">
        {submittingState === "success" && (
          <div>
            <div>
              <CheckCircleIcon color="success" fontSize="large" />
            </div>
            <h3>You're all set!</h3>
            <div className="checkout-section-subheader">
              You've been added to the waitlist for the routes below and will be notified at <b>{phoneNumber}</b> if a
              spot opens up.
            </div>
            <RequestedRoutes requestedRoutes={requestedRoutes} />
            <div style={{ marginTop: "20px", paddingBottom: "16px" }}>
              If this service was helpful, consider{" "}
              <a target="_blank" rel="noopener noreferrer" href="https://buymeacoffee.com/nextsailing">
                buying me a coffee ☕️
              </a>
              .
            </div>
          </div>
        )}
        {submittingState !== "success" && (
          <>
            <h3>Almost done!</h3>
            <div className="checkout-section-subheader">
              Enter your phone number below and we'll send you a text when your BC Ferry routes are available for
              reservation.
            </div>
            <MuiTelInput
              value={phoneNumber}
              label="Enter phone number"
              onChange={(value) => {
                setPhoneNumber(value)
                setPhoneNumberValid(
                  matchIsValidTel(value, {
                    onlyCountries: ["CA", "US"],
                  })
                )
              }}
              onlyCountries={["CA", "US"]}
              defaultCountry="CA"
              unknownFlagElement={<div></div>}
              className="checkout-phone-number"
              error={phoneNumber.length > 0 && !phoneNumberValid}
              helperText={
                phoneNumber.length > 0 && !phoneNumberValid ? "Please enter a valid US or Canadian phone number" : ""
              }
            />

            <div className="checkout-form">{errorMessage && <div>{errorMessage}</div>}</div>

            <div className="checkout-terms-and-conditions">
              By entering your phone number and clicking "Finish" below you agree to receive text messages related to
              your BC Ferry routes from this service. <br />
              <br />
              The service does not purchase reservations on your behalf and cannot guarantee reservation availability.
              There is no charge for this service, though standard text message rates may apply.
              <br />
              <br />
              This service is not affiliated with or endorsed by BC Ferries.
            </div>

            <ActionsFooter
              showFooter={true}
              canContinue={phoneNumberValid && submittingState !== "submitting"}
              canCancel={true}
              cancelLabel="Back"
              continueLabel={submittingState === "submitting" ? "Submitting..." : "Finish"}
              onContinue={async () => {
                doAction("ClickedFinishCheckout")
                setErrorMessage(null)
                setSubmittingState("submitting")
                await submitNotificationRequest()
                setSubmittingState("success")
                doAction("FinishCheckoutSuccess")
              }}
              onCancel={() => {
                doAction("ClickedCancelCheckout")
                setIsCheckingOut(false)
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Checkout
