import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c69d912a99c9e217a598e4bcf832b372@o1341054.ingest.us.sentry.io/4508011382964224",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/nextsailing\.ca/],
  })
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
