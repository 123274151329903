import { ArrowForward } from "@mui/icons-material"
import { Button, styled } from "@mui/material"

const CancelButton = styled(Button)({
  color: "white",
})

function ActionsFooter({
  continueLabel,
  onContinue,
  onCancel,
  cancelLabel,
  showFooter,
  canCancel,
  canContinue,
}: {
  continueLabel: string
  onContinue: () => void
  onCancel: () => void
  cancelLabel: string
  showFooter: boolean
  canCancel: boolean
  canContinue: boolean
}) {
  if (!showFooter) {
    return null
  }

  return (
    <div className="route-picker-footer">
      <div className="route-picker-footer-content">
        <CancelButton
          size="small"
          variant="text"
          color="secondary"
          onClick={onCancel}
          style={{ minWidth: 100, visibility: canCancel ? "visible" : "hidden" }}
          disabled={!canCancel}
        >
          {cancelLabel}
        </CancelButton>
        <div>
          <Button
            sx={{
              minWidth: 160,
            }}
            size="small"
            variant="contained"
            color="primary"
            onClick={onContinue}
            disabled={!canContinue}
            endIcon={<ArrowForward />}
          >
            {continueLabel}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ActionsFooter
